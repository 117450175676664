<template>
  <div
    class="flex h-full min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <NuxtImg
        preload
        class="mx-auto h-20 w-auto"
        src="/images/logo.png"
        alt="PadelCast"
        width="150" />
      <h1 class="text-center font-title text-7xl text-gray-700">PadelCast</h1>
      <h2
        class="mt-2 text-center font-secondary text-2xl leading-9 text-gray-900">
        Accedi al tuo account
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <div>
          <div class="mb-6 grid gap-4">
            <!-- TODO: create component -->
            <a
              class="flex w-full items-center justify-center gap-2 rounded-md bg-[#DB4437] px-4 py-2 font-semibold text-white hover:cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#DB4437]"
              @click="handleGoogle">
              <IconsGoogle class="h-6 w-6" />
              <span>Accedi con Google</span>
            </a>
            <a
              class="flex w-full items-center justify-center gap-2 rounded-md bg-black px-4 py-2 font-semibold text-white hover:cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
              @click="handleApple">
              <IconsApple class="h-6 w-6" />
              <span>Accedi con Apple</span>
            </a>
          </div>

          <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
            <Disclosure v-slot="{ open }" as="div" class="space-y-6">
              <dt>
                <DisclosureButton
                  class="flex w-full items-start justify-between text-left text-gray-900">
                  <div class="relative mb-6">
                    <div
                      class="absolute inset-0 flex items-center"
                      aria-hidden="true">
                      <div class="w-full border-t border-gray-200" />
                    </div>
                    <div
                      class="relative flex justify-center text-sm font-medium leading-6">
                      <span class="bg-white px-6 text-gray-900">
                        Oppure accedi con la tua email
                      </span>
                    </div>
                  </div>
                  <span class="ml-6 flex h-7 items-center">
                    <ChevronUpIcon
                      :class="open ? 'rotate-180 transform' : ''"
                      class="h-5 w-5" />
                  </span>
                </DisclosureButton>
              </dt>
              <DisclosurePanel as="dd">
                <form @submit.prevent="handleMagicLink">
                  <div class="mb-6 grid gap-2">
                    <label
                      for="email"
                      class="block text-sm font-medium leading-6 text-gray-900">
                      Indirizzo email
                    </label>
                    <input
                      id="email"
                      v-model="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      required
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-celestial sm:text-sm sm:leading-6" />
                  </div>

                  <div>
                    <button
                      type="submit"
                      class="flex w-full justify-center rounded-md bg-celestial px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-celestialsec focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Accedi
                    </button>
                  </div>
                </form>
              </DisclosurePanel>
            </Disclosure>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon } from '@heroicons/vue/20/solid'

definePageMeta({
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/',
  },
})

const { signIn } = useAuth()
const route = useRoute()

const email = ref('')

const handleGoogle = async () => {
  await signIn('google', {
    callbackUrl: route.query.callbackUrl ? route.query.callbackUrl : '/',
  })
}

const handleApple = async () => {
  await signIn('apple', {
    callbackUrl: route.query.callbackUrl ? route.query.callbackUrl : '/',
  })
}

const handleMagicLink = async () => {
  try {
    await signIn('magic-link', { email: email.value })
    alert('Controlla la tua email per accedere.')
  } catch (error) {
    alert("Errore durante l'invio dell'email. Per favore riprova.")
  }
}
</script>

<style></style>
